<script lang="ts">
    import { page } from '$app/stores';
    import ShareButtonList from '$lib/components/share-buttons/ShareButtonList.svelte';
    import { C9_CATEGORY_SEO } from '$lib/config/config-pages';
    import { t } from '$lib/i18n/i18n-store';
    import type { PageDataModel } from '$lib/models/page.models';
    import { getModalStore } from '@skeletonlabs/skeleton';
    import { onDestroy } from 'svelte';

    export let parent: any;

    const modalStore = getModalStore();
    let seoValues: PageDataModel;
    let urlHref: string;

    $: {
        seoValues = $modalStore[0]?.meta?.seoValues;
    }

    const unsubscribePage = page.subscribe((pageData) => {
        urlHref = pageData.url.href;
        const routeId = pageData.route?.id;
        if (!routeId) {
            return;
        }

        const pageTagValues = C9_CATEGORY_SEO[routeId];

        seoValues = {
            ...pageTagValues,
            seoDescription: seoValues?.seoDescription ?? encodeURIComponent($t(pageTagValues?.seoDescription)),
            seoTitle: seoValues?.seoTitle ?? encodeURIComponent($t(pageTagValues?.seoTitle)),
            urlPath: seoValues?.urlPath ?? encodeURIComponent(urlHref),
        };
    });

    onDestroy(() => {
        unsubscribePage();
    });
</script>

<ShareButtonList closeButton {seoValues} {urlHref} on:close={parent.onClose} />
