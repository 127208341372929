<script lang="ts">
    import SocialNetworkButton from '$lib/components/share-buttons/SocialNetworkButton.svelte';
    import type { PageDataModel } from '$lib/models/page.models';
    import { createEventDispatcher } from 'svelte';
    import MdContentCopy from 'svelte-icons/md/MdContentCopy.svelte';

    export let closeButton: boolean = false;
    export let seoValues: PageDataModel;
    export let urlHref: string;

    let clipboardInput: HTMLInputElement;
    const dispatch = createEventDispatcher();

    $: urlHref = seoValues?.urlPath ?? urlHref;

    const copyToClipboard = async () => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(urlHref);
        }
        else if (typeof document.execCommand === 'function') {
            clipboardInput.click();
            clipboardInput.select();
            document.execCommand('copy');
        }
        doClose();
    };

    const doClose = (e?: MouseEvent) => {
        dispatch('close', e);
    }
</script>

<div class="card bg-neutral-50 backdrop-blur-sm overflow-hidden w-[99%] md:w-8/12 lg:w-auto shadow-xl mt-2 mb-auto p-5 pt-7">
    {#if closeButton}
        <button class="absolute -top-2.5 -right-2.5 z-1 btn-icon text-teal-900 focus:outline-0 hover:text-teal-800" on:click={doClose}>✕</button>
    {/if}
    <div class="flex flex-wrap justify-center w-full">
        <SocialNetworkButton {seoValues} network="linkedin" />
        <SocialNetworkButton {seoValues} network="whatsapp" />
        <SocialNetworkButton {seoValues} network="facebook" />
        <SocialNetworkButton {seoValues} network="email" />
        <SocialNetworkButton {seoValues} network="telegram" />
    </div>
    <div class="relative flex flex-row items-center w-full pl-1 pr-2 py-1 bg-white border border-r-0 border-teal-800 border-opacity-50 rounded">
        <input id="c9-clipboard-input" type="text" class="grow c9-clipboard-input appearance-none text-gray-950 border-none rounded py-1 pl-1 pr-14 " disabled
               bind:this={clipboardInput}
               value={urlHref} />
        <button type="button" class="btn btn-sm variant-ghost-primary right-0 absolute text-teal-900" on:click={copyToClipboard}>
            <div class="w-[1.9rem] h-[1.9rem]">
                <MdContentCopy />
            </div>
        </button>
    </div>
</div>
