<script lang="ts">
    import { page } from '$app/stores';
    import { C9_CATEGORY_SEO } from '$lib/config/config-pages';
    import { t } from '$i18n/i18n-store';
    import { environmentVars } from '$lib/environment';
    import type { PageMetaTag, PageDataModel } from '$lib/models/page.models';
    import { isStringNotEmpty } from '@rigu/js-toolkit';
    import { onDestroy } from 'svelte';

    const basePath = environmentVars.basePath ?? '';
    let headValues: PageDataModel;
    let metaTags: PageMetaTag[] = [];

    const unsubscribePage = page.subscribe((pageData) => {
        const routeId = pageData.route?.id;

        if (!routeId) {
            return;
        }

        metaTags = [];
        headValues = C9_CATEGORY_SEO[routeId];

        if (headValues) {
            const description = $t(headValues.seoDescription ?? 'home.description');
            const title = $t(headValues.seoTitle ?? headValues.title ?? 'home.title');
            const url = basePath + routeId;

            metaTags.push(
                {property: 'title', content: title},
                {property: 'description', content: description},
                {property: 'keywords', content: headValues.keywords ?? ''},
                {property: 'url', content: url},
                {property: 'og:description', content: description},
                {property: 'og:title', content: title},
                {property: 'og:url', content: url},
            );

            if (isStringNotEmpty(headValues.seoImgPath)) {
                // const imagePath = urlOrigin + headValues.seoImgPath;
                const imagePath = basePath + headValues.seoImgPath;
                metaTags.push(
                    {property: 'image', content: imagePath},
                    {property: 'og:image', content: imagePath},
                    {property: 'og:image:secure_url', content: imagePath},
                );

                if (!!headValues.seoImgHeight) {
                    metaTags.push(
                        {property: 'image:height', content: headValues.seoImgHeight},
                        {property: 'og:image:height', content: headValues.seoImgHeight},
                    );
                }

                if (!!headValues.seoImgType) {
                    metaTags.push(
                        {property: 'image:type', content: headValues.seoImgType},
                        {property: 'og:image:type', content: headValues.seoImgType},
                    );
                }

                if (!!headValues.seoImgWidth) {
                    metaTags.push(
                        {property: 'image:width', content: headValues.seoImgWidth},
                        {property: 'og:image:width', content: headValues.seoImgWidth},
                    );
                }
            }
        }
    });

    onDestroy(() => {
        unsubscribePage();
    });
</script>

<svelte:head>
    <title>{$t(headValues?.seoTitle ?? headValues?.title ?? 'home.title')}</title>
    <!-- Meta Tags -->
    <meta name="author" content={$t('app.name')}/>
    <meta name="type" content="website">

    <!-- Open Graph - https://ogp.me/ -->
    <meta property="og:locale" content="ro_RO"/>
    <meta property="og:site_name" content={$t('app.name')}/>
    <meta property="og:type" content="website"/>
    {#each metaTags as metaTag, i (metaTag.property)}
        <meta property={metaTag.property} content={metaTag.content}/>
    {/each}

    <!-- OG: Article -->
    <!--{#if isBlogArticle}-->
    <!--    <meta property="article:published_time" content={meta.article.publishTime} />-->
    <!--    <meta property="article:modified_time" content={meta.article.modifiedTime} />-->
    <!--    <meta property="article:author" content={meta.article.author} />-->
    <!--{/if}-->

    <!-- Open Graph: Twitter -->
    <!--    <meta name="twitter:card" content="summary" />-->
    <!--    <meta name="twitter:site" content="@SkeletonUI" />-->
    <!--    <meta name="twitter:creator" content="@SkeletonUI" />-->
    <!--    <meta name="twitter:title" content={meta.twitter.title} />-->
    <!--    <meta name="twitter:description" content={meta.twitter.description} />-->
    <!--    <meta name="twitter:image" content={meta.twitter.image} />-->
</svelte:head>
